import React from 'react';
import { Container, Col, Row } from "react-bootstrap";
import parse from "html-react-parser";
import { Link, graphql } from "gatsby";
import GetGGFXImage from '../components/common/site/get-ggfx-image';
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Breadcrumb from "../components/Breadcrumb/site-breadcrumbs";
import SEO from '../components/Seo/seo'

const OfficeDetails = ({data}) => {
    const office = data.glstrapi.office

    // 
    let processedImages = JSON.stringify({});
    if (office?.imagetransforms?.Banner_Image_Transforms) {
        processedImages = office.imagetransforms.Banner_Image_Transforms;
    }
    //

    return (
        <>
            <SEO
                title={`Estate agents in ${office?.Name}`}
                description={office.Meta_Description ? office.Meta_Description : `Our estate agents in ${office?.Name} offer the best property advice in Selling, Buying, Letting and Renting. Contact us to get assistance.`}
            />
            <Header />
                <section className="office-detail-wrapper">
                    <Container>
                        <Row>
                            <Col lg={5} className="order-lg-1 order-2">
                                <div className="d-lg-block d-none">
                                    <Breadcrumb style="default-breadcrumbs"  alias="contact" menulabel={office?.Name} />
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="office-details">
                                        <div className="office-heading">
                                            <h1>{office?.Name}</h1>
                                            <p>{office?.Office_Address}</p>
                                            {office.URL === "islington-office" ?
                                            <a href="https://www.google.com/maps/place/David+Astburys+Estate+Agents+Islington+N1/@51.5375311,-0.102598,15z/data=!4m2!3m1!1s0x0:0xd44a93637a73bcd2?sa=X&ved=2ahUKEwix1t_D59_6AhXEcGwGHfFiCF8Q_BJ6BAhLEAU" target={`_blank`}>View on a map</a>
                                            :
                                            office.URL === "crouch-end-office-and-property-management-office" ?
                                            <a href="https://www.google.com/maps/place/David+Astburys+Estate+Agents+In+Crouch+End,+London+N8+8TE/@51.5806045,-0.1247492,15z/data=!4m2!3m1!1s0x0:0xd673cba56113beb4?sa=X&ved=2ahUKEwjilvaO59_6AhVXTWwGHQCwBQEQ_BJ6BAhZEAU" target={`_blank`}>View on a map</a>
                                            :
                                            <a href="https://maps.app.goo.gl/BuUejrxoZ8EX7bk99" target={`_blank`}>View on a map</a>
                                            }
                                        </div>
                                        
                                        <div className="office-btns d-md-block d-none">
                                            <div className="d-flex justify-content-start">
                                                <Link to={`/contact-us/`} state={{ officeid: office.id,officeemail: office.Email,officename: office.Name}}className="btn btn-fill-primary">Contact Office</Link>
                                                <a href={`tel:${office.Phone}`} className="btn btn-dark">Call {office?.Phone}</a>
                                            </div>
                                        </div>

                                        <div className="office-hours">
                                            <h3>Opening Hours</h3>
                                            <ul>
                                                <li><div className="office-day"><p>Monday - Thursday:</p> <span>08:30am - 6:30pm</span></div></li>
                                                <li><div className="office-day"><p>Friday:</p> <span>08:30am - 6:00pm</span></div></li>
                                                <li><div className="office-day"><p>Saturday</p> <span>10:00am - 4:00pm</span></div></li>
                                                <li><div className="office-day"><p>Sunday</p> <span>Closed</span></div></li>
                                            </ul>
                                        </div>

                                        <div className="office-about">
                                            <h3>About this Office</h3>
                                            {parse(office.About_Office)}
                                        </div>

                                        <div className="office-uselinks">
                                            <div className="office-links-heading">Useful Links</div>
                                            <ul className="list-inline mb-0">
                                                <li className="list-inline-item mb-0">
                                                    <Link to="/our-culture/meet-the-team/">Meet the team</Link>
                                                </li>
                                                <li className="list-inline-item mb-0">
                                                    <Link to="/property/for-sale/in-london/">Homes for Sale</Link>
                                                </li>
                                                <li className="list-inline-item mb-0">
                                                    <Link to="/property/to-rent/in-london/">Homes to Rent</Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-xl-none d-lg-none d-md-none d-flex align-items-center office-details-btns-fixed">
                                    <Link to={`/contact-us/`} state={{ officeid: office.id,officeemail: office.Email,officename: office.Name}} className="btn btn-fill-primary">Contact Office</Link>
                                    <a href={`tel:${office.Phone}`} className="btn btn-dark d-flex align-items-center">Call <i className="icon icon-phone"></i></a>
                                </div>
                            </Col>
                            <Col lg={1} className="order-lg-2 order-2"></Col>
                            <Col lg={6} className="order-lg-3 order-1">
                                <div className="d-lg-none d-md-block d-none office-details-breadcrumb">
                                    <Breadcrumb style="default-breadcrumbs"  alias="contact" menulabel={office?.Name} />
                                </div>
                                <div className="d-xl-none d-lg-none d-md-none d-flex align-items-center office-back-arrow">
                                    <Link to="/contact/" className="d-flex align-items-center"><i className="icon icon-back-arrow"></i> Back to Offices</Link>
                                </div>
                                <div className="contact-banner">
                                    {
                                        <GetGGFXImage 
                                            imagename={"offices.Banner_Image.bannerimg"} 
                                            imagesource={office?.Banner_Image} 
                                            fallbackalt={office?.Name} 
                                            imagetransformresult={processedImages} 
                                            id={office?.id}
                                        />
                                    }
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            <Footer popularsearch="pagedetails" area={office?.Name}/>
        </>
    )
}

export default OfficeDetails

export const pageQuery = graphql`
query GetOfficeDetailPage($articleId: ID!) {
    glstrapi {
        office(id: $articleId, publicationState: LIVE) {
            id
            URL
            Name
            Meta_Title
            Meta_Description
            Phone
            Email
            Office_Address
            Latitude
            Longitude
            About_Office
            imagetransforms
            Banner_Image {
                url
            }
        }
    }
}
`
